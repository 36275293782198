import './CheckBox.css';

const CheckBox = ({ id, className, checkBoxState, toggleSwitchHandler }) => (
    <label className="switch">
        <input id={id} className={className} type="checkbox" checked={checkBoxState} onChange={(e) => toggleSwitchHandler(e)} />
        <span className="slider round" />
    </label>
);

export default CheckBox;
