const createCardsToStudy = (hanziListToStudy, hanziListFromDatabase, isFromLearn, shuffleCards) => {
    const getRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const createIncorrectOptions = (correctOption) => {
        const incorrectArray = [];

        while (incorrectArray.length < 3) {
            const randomNumber = getRandomNumber(0, hanziListFromDatabase.length - 1);
            const wrongOption = hanziListFromDatabase[randomNumber].pinyin;
            const wrongOptionIsAlreadyUsed = incorrectArray.some((item) => item.option === wrongOption);
            const wrongOptionIsCorrectOption = wrongOption === correctOption;

            if (!wrongOptionIsAlreadyUsed && !wrongOptionIsCorrectOption) {
                incorrectArray.push({ option: wrongOption,
                                      optionType: false });
            }
        }

        return incorrectArray;
    };

    const createOptions = (correctOption) => {
        const options = createIncorrectOptions(correctOption);
        const positionToInsertRightOption = getRandomNumber(0, 3);
        const rightOption = { option: correctOption, optionType: true };
        options.splice(positionToInsertRightOption, 0, rightOption);

        return options;
    };

    const shuffleArray = (array) => {
        let j;
        let temp;
        for (let i = array.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };

    if (isFromLearn) {
        const hanziListByFive = [];
        for (let i = 0; i < 5; i++) {
            hanziListByFive.push(...hanziListToStudy);
        }
        hanziListToStudy.push(...hanziListByFive);
    }

    let cardsToStudy = [];
    hanziListToStudy.forEach((item) => {
        const hanziData = hanziListFromDatabase.find((hanzi) => hanzi.id === item.id);
        const options = createOptions(hanziData.pinyin);
        cardsToStudy.push({
            id: hanziData.id,
            hanzi: hanziData.hanzi,
            pinyin: hanziData.pinyin,
            options,
            meaning: hanziData.meaning,
        });
    });

    if (!isFromLearn && shuffleCards) {
        cardsToStudy = shuffleArray(cardsToStudy);
    }

    const reversedListOfCardsToStudy = [...cardsToStudy].reverse();

    return reversedListOfCardsToStudy;

};

export default createCardsToStudy;
