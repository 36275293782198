import axios from 'axios';
import { getHanziUrl } from './apiUrls';

const normalizeHanziList = (hanziFromDatabase) => {
    const normalizedHanziList = [];
    hanziFromDatabase.forEach((item) => {
      const normalizedHanzi = {
        id: Number(item.id),
        hanzi: item.caracter,
        pinyin: item.pinyin,
        meaning: item.significados_es,
      };
      normalizedHanziList.push(normalizedHanzi);
    });

    return normalizedHanziList;
};

const getHanziListFromDatabase = async () => {
  const hanziList = await axios.post(getHanziUrl, { key: 'hanzicards' })
    .then((responseHanzi) => {
      const normalizedHanzifromDataBase = normalizeHanziList(responseHanzi.data);
      localStorage.setItem('hanziList', JSON.stringify(normalizedHanzifromDataBase));
      return normalizedHanzifromDataBase;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  return hanziList;
};

export default getHanziListFromDatabase;
