import { IoMdArrowRoundBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import './NavBackButton.css';

const NavBackButton = () => {
    const history = useHistory();
    const click = () => {
        history.push('/panel');
    };

    return (
        <button className="back__button" onClick={click}>
            <IoMdArrowRoundBack className="back__icon" />
            Volver
        </button>
    );
};

export default NavBackButton;
