import { useContext } from 'react';
import ReactDOM from 'react-dom';
import { ModalContext } from '../context/ModalContext';
import './Modal.css';

const Modal = ({ modalClickable = false }) => {
    const { modalState, setModalState, modalContent } = useContext(ModalContext);

    const changeModalState = () => {
        setModalState(!modalState);
    };

    const onModalClick = (e) => {
        const modalBackgroundisClicked = e.target.className.includes('modal');

        if (modalBackgroundisClicked) {
            changeModalState();
        }
    };

    const ModalContainer = () => (
        <div className="modal__background" onClick={modalClickable ? onModalClick : null}>
            {modalContent}
        </div>
    );

    if (modalState) {
        return ReactDOM.createPortal(
            <ModalContainer />,
            document.querySelector('#modal-root')
        );
    }

    return null;
};

export default Modal;
