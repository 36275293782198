import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserDataContext } from '../../context/UserDataContext';
import { ModalContext } from '../../context/ModalContext';

const ModalResetProgress = () => {
    const { updateUserHanziList, resetUserLevel } = useContext(UserDataContext);
    const { setModalState } = useContext(ModalContext);
    const history = useHistory();

    const resetLevel = () => {
        closeModal();
        updateUserHanziList([]);
        resetUserLevel();
        history.push('/panel');
    };

    const closeModal = () => {
        setModalState(false);
    };

    return (
        <div className="modal__content modal__reset">
            <h2 className="reset__title">¡Atención!</h2>
            <p>Esta acción reseteará todos los caracteres y empezarás de nuevo desde el nivel 1.</p>
            <div className="reset__buttons">
                <h3 className="reset__question">¿Quieres continuar?</h3>
                <button className="reset__button reset__button--confirm" onClick={resetLevel}>Si, Resetear todo</button>
                <button className="reset__button reset__button--cancel" onClick={closeModal}>No, Volver atrás</button>
            </div>
        </div>
    );
};

export default ModalResetProgress;
