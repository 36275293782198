import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { HanziDataContext } from '../context/HanziDataContext';
import { UserDataContext } from '../context/UserDataContext';
import './RevisionDetails.css';

const RevisionDetails = ({ lastHanziAllowedToGetInfo, closeModalHandler }) => {
    const { hanziListFromDatabase } = useContext(HanziDataContext);
    const { userLevel } = useContext(UserDataContext);
    const hanziLearned = hanziListFromDatabase.filter((item) => item.id > (lastHanziAllowedToGetInfo - 5) && item.id <= lastHanziAllowedToGetInfo);
    const hanziLearnedList = hanziLearned.map((hanzi, index) => {
        return index === 3
        ? <><br /><span>{hanzi.hanzi}</span></>
        : <span>{hanzi.hanzi}</span>;
    });

    return (
        <>
            <div>
                <h2 className="revision-details__title">TARJETA DE REVISIÓN<br />-- NIVEL {userLevel} --</h2>
                <p>A continuación se muestran los caracteres aprendidos en este nivel.</p>
                <p>Para pasar al siguiente nivel deberás hacer una prueba de nivel y superar la cantidad mínima de aciertos.</p>
            </div>
            <h3 className="revision-details__hanzilist">{hanziLearnedList}</h3>
            <div className="revision-details__button-wrapper">
                <Link
                  to={{ pathname: '/panel/study', state: { from: 'learn', hanziToStudy: hanziLearned } }}
                  onClick={closeModalHandler}
                  className="revision-details__button"
                >
                Hacer la prueba de nivel
                </Link>
            </div>
        </>
    );
};

export default RevisionDetails;
