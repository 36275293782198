import axios from 'axios';
import { updateProgressUrl, updateOptionsUrl } from './apiUrls';

export const updateProgressInDatabase = async (userId, userLevel, userHanziList, token) => {
    const userHanziListStringified = JSON.stringify(userHanziList);

    const update = await axios.post(updateProgressUrl, {
         id: userId,
         userLevel,
         userHanziList: userHanziListStringified,
         token,
         key: 'hanzicards'
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => console.log(error));

    return update;
};

export const updateOptionsInDatabase = async (userId, userOptions, token) => {
    const userOptionsStringified = JSON.stringify(userOptions);

    const update = await axios.post(updateOptionsUrl, {
        id: userId,
        userOptions: userOptionsStringified,
        token,
        key: 'hanzicards'
       })
       .then((response) => {
           return response.data;
       })
       .catch((error) => console.log(error));

    return update;
};
