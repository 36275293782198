export const changePasswordUrl = 'https://www.curso-chino-basico.com/hanzicards/api/change_password.php';

export const createGuestUrl = 'https://www.curso-chino-basico.com/hanzicards/api/create_guest.php';

export const createUserUrl = 'https://www.curso-chino-basico.com/hanzicards/api/create_user.php';

export const editUserUrl = 'https://www.curso-chino-basico.com/hanzicards/api/edit_user.php';

export const getHanziUrl = 'https://www.curso-chino-basico.com/hanzicards/api/get_hanzi.php';

export const loginUrl = 'https://www.curso-chino-basico.com/hanzicards/api/login.php';

export const updateProgressUrl = 'https://www.curso-chino-basico.com/hanzicards/api/update_progress.php';

export const updateOptionsUrl = 'https://www.curso-chino-basico.com/hanzicards/api/update_options.php';

export const sendPasswordTokenUrl = 'https://www.curso-chino-basico.com/hanzicards/api/send_password_token.php';

export const checkPasswordTokenUrl = 'https://www.curso-chino-basico.com/hanzicards/api/check_password_token.php';

export const resetPasswordUrl = 'https://www.curso-chino-basico.com/hanzicards/api/reset_password.php';
