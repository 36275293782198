import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ModalContext } from '../../context/ModalContext';
import useResetStates from '../../hooks/useResetStates';

const SessionExpiredModalContent = () => {
    const { setModalState } = useContext(ModalContext);
    const [resetStates] = useResetStates();

    const closeModal = () => {
        setModalState(false);
        resetStates();
    };

    return (
        <div className="modal__content modal__expired-session">
            <h2>La sesión ha caducado</h2>
            <p>La sesión actual ha expirado, por favor, inicia sesión de nuevo para continuar.</p>
            <div className="buttons__wrapper">
                <Link to="/login" onClick={closeModal} className="modal__expired-button">Volver a iniciar sesión</Link>
            </div>
        </div>
    );
};

export default SessionExpiredModalContent;
