import { FaThumbsUp, FaThumbsDown, FaLayerGroup } from 'react-icons/fa';
import './StudyCounters.css';

const StudyCounters = ({ correctAnswers, wrongAnswers, remainCards }) => (
    <div className="counters__container">
        <div className="counter__single">
            <FaThumbsUp className="counter__icon counter__icon--correct" />
            <span className="counter__number">{correctAnswers}</span>
        </div>
        <div className="counter__single">
            <FaThumbsDown className="counter__icon counter__icon--wrong" />
            <span className="counter__number">{wrongAnswers}</span>
        </div>
        <div className="counter__single">
            <FaLayerGroup className="counter__icon counter__icon--remain" />
            <span className="counter__number">{remainCards}</span>
        </div>
    </div>
);

export default StudyCounters;
