import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';

const ModalStudyCompleted = ({ studyCompletedMessage, studyAgain, isFromLearn }) => {
    const { setModalState } = useContext(ModalContext);
    const { studySuccess, title, message, levelUp } = studyCompletedMessage;

    const closeModal = () => {
        setModalState(false);
    };

    const repeatStudy = () => {
        closeModal();
        studyAgain();
    };

    return (
        <div className="modal__content modal__study">
            {studyCompletedMessage.studySuccess ? <img src="/img/trophy.png" alt="test pass" /> : <img src="/img/testfail.png" alt="test fail" /> }
            <h2 className="modal__study-title">{title}</h2>
            <p>{message}</p>
            <div className="buttons__wrapper">
                {isFromLearn && !studySuccess && !levelUp && (
                    <button className="modal__study-button modal__study-button--again" onClick={repeatStudy}>
                        Repetir la prueba
                    </button>
                )}
                {!isFromLearn && (
                    <button className="modal__study-button modal__study-button--again" onClick={repeatStudy}>
                        Volver a estudiar
                    </button>
                )}
                <Link to="/panel" className="modal__study-button modal__study-button--back" onClick={closeModal}>
                    Ir al panel
                </Link>
            </div>
        </div>
    );
};

export default ModalStudyCompleted;
