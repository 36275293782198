import axios from 'axios';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import { useState, useContext, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { HanziDataContext } from '../context/HanziDataContext';
import { UserLoginContext } from '../context/UserLoginContext';
import { UserDataContext } from '../context/UserDataContext';
import { loginUrl } from '../helpers/apiUrls';
import getHanziListFromDatabase from '../helpers/getHanziList';
import updateLocalStorage from '../helpers/updateLocalStorage';
import './Login.css';

const Login = () => {
    const { setUserId, setUserName, setToken, setIsGuest, setUserOptions, setUserHanziList, setUserLevel, setUserEmail } = useContext(UserDataContext);
    const { setIsLogged } = useContext(UserLoginContext);
    const { setHanziListFromDatabase } = useContext(HanziDataContext);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [apiResponse, setApiResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const history = useHistory();

    const onLoginSubmit = async (e) => {
        e.preventDefault();
        const emailIsFilled = emailRef.current.reportValidity();
        const passIsFilled = passwordRef.current.reportValidity();

        if (!emailIsFilled || !passIsFilled) {
            return;
        }

        setLoading(true);
        axios.post(loginUrl, formData)
            .then((response) => {
                const { success, message } = response.data;
                setApiResponse({ success, message });
                if (success) {
                    onLoginSuccess(response.data);
                } else {
                    setLoading(false);
                }
                })
            .catch((error) => {
                setApiResponse(error.message);
                setLoading(false);
            });
    };

    const onLoginSuccess = (data) => {
        ReactGA.event({
            category: 'General',
            action: 'Usuario logeado'
        });
        setLocalStorage(data);
        setStates(data);
        getHanziListFromDatabase()
            .then((hanziList) => {
                setHanziListFromDatabase(hanziList);

                setTimeout(() => {
                    setIsGuest(false);
                    setIsLogged(true);
                    history.push('/panel');
                }, 500);
            });
    };

    const setLocalStorage = (data) => {
        const { id, username, email, userLevel, token, userOptions, userHanziList } = data;
        const parsedUserOptions = JSON.parse(userOptions);
        const parsedUserHanziList = JSON.parse(userHanziList);
        updateLocalStorage('userData', { isLogged: true,
                                         isGuest: false,
                                         id: Number(id),
                                         email,
                                         userLevel: Number(userLevel),
                                         userOptions: parsedUserOptions,
                                         userHanziList: parsedUserHanziList,
                                         username,
                                         token
                                        });
    };

    const setStates = (data) => {
        const { id, username, email, userLevel, token, userOptions, userHanziList } = data;
        setUserId(Number(id));
        setUserHanziList(JSON.parse(userHanziList));
        setUserLevel(Number(userLevel));
        setUserName(username);
        setUserOptions(JSON.parse(userOptions));
        setUserEmail(email);
        setToken(token);
    };

    const inputChangeHandler = (e) => {
        const objProp = e.target.name;
        setApiResponse('');
        setFormData({ ...formData,
                     [objProp]: e.target.value });
    };

    const loadingAnimation = <img className="home__button-loading" src="/img/loading.svg" alt="" />;

    const apiResponseClass = apiResponse?.success ? 'login__message--success' : 'login__message--error';

    return (
        <main>
            <Helmet>
                <title>Inicia sesión - Hanzi-cards.com</title>
                <meta name="description" content="Inicia sesión para comenzar a aprender los caracteres chinos más usados." />
            </Helmet>

            <div className="login__container">
                <h1 className="login__title">Inicia sesión:</h1>
                <form className="login__form" onSubmit={(e) => onLoginSubmit(e)}>
                    <label htmlFor="email" className="login__label">Correo electrónico</label>
                    <input ref={emailRef} type="text" className="login__input" name="email" onChange={inputChangeHandler} required />
                    <label htmlFor="password" className="login__label">Contraseña</label>
                    <input ref={passwordRef} type="password" className="login__input" name="password" onChange={inputChangeHandler} required />

                    <p className={apiResponseClass}>{apiResponse.message}</p>

                    <button className="login__button">
                        {loading ? loadingAnimation : 'Acceder'}
                    </button>
                    <Link to="/reset-password" className="login__forget-password">He olvidado mi contraseña</Link>
                </form>
            </div>
        </main>
    );
};

export default Login;
