import { useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';
import HanziDetails from '../HanziDetails';

const ModalHanziDetails = ({ hanzi, currentPage, openModalHandler = null, nextCardHandler = null }) => {
    const { setModalState } = useContext(ModalContext);

    const closeModal = () => {
        setModalState(false);
    };

    return (
        <>
            <div className="modal__content modal__cards">
                <HanziDetails hanzi={hanzi} currentPage={currentPage} openModalHandler={openModalHandler} nextCardHandler={nextCardHandler} />
            </div>
            <button className="modal__button-close" onClick={closeModal}>Cerrar</button>
        </>
    );
};

export default ModalHanziDetails;
