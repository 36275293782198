import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';
import Home from './pages/Home';
import Panel from './pages/Panel';
import Login from './pages/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import ResetPassword from './components/ResetPassword';
import { UserDataContextProvider } from './context/UserDataContext';
import { UserLoginProvider } from './context/UserLoginContext';
import { HanziDataContextProvider } from './context/HanziDataContext';
import { ModalContextProvider } from './context/ModalContext';
import usePageViews from './hooks/usePageViews';
import Modal from './components/Modal';


function App() {
  useEffect(() => {
    ReactGA.initialize('UA-79843257-7', { titleCase: false });
  }, []);

  usePageViews();

  return (
    <>
      <HelmetProvider>
      <UserDataContextProvider>
      <HanziDataContextProvider>
      <UserLoginProvider>
      <ModalContextProvider>

        <Header />

        <Switch>
            <Route path="/panel" component={Panel} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/login" component={Login} />
            <Route path="/" component={Home} />
        </Switch>

        <Footer />

        <Modal />

      </ModalContextProvider>
      </UserLoginProvider>
      </HanziDataContextProvider>
      </UserDataContextProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
