export default function moveCards(cardIndex, isCorrectAnswer) {
    const container = document.querySelector('.study__container');
    const firstCard = document.querySelector(`.study-card--${cardIndex}`);
    container.insertBefore(firstCard, container[container.length - 1]);

    setTimeout(() => {
        changeCardBackground(firstCard, isCorrectAnswer);
    }, 5);

    // setTimeout necesario para que las transiciones de las tarjetas no se corten por el inserBefore
    setTimeout(() => {
        moveFirstCard(firstCard, isCorrectAnswer);

        // Si quedan al menos dos cartas mueve la segunda
        if (cardIndex >= 1) {
            moveSecondCard(cardIndex - 1);
        }

        // Si quedan al menos tres cartas mueve la tercera
        if (cardIndex >= 2) {
            moveThirdCard(cardIndex - 2);
        }
    }, 300);
}

function changeCardBackground(firstCard, isCorrectAnswer) {
    const firstCardFront = firstCard.querySelector('.study-card__front');
    if (isCorrectAnswer) {
        firstCardFront.classList.add('study-card__front--correct');
    } else {
        firstCardFront.classList.add('study-card__front--wrong');
    }
}

function moveFirstCard(firstCard, isCorrectAnswer) {
    const firstCardFlipWrapper = firstCard.querySelector('.study-card__flip-wrapper');

    if (isCorrectAnswer) {
        firstCard.classList.add('study-card--correct');
        firstCardFlipWrapper.classList.add('study-card__flip-wrapper--correct');
    } else {
        const firstCardBack = firstCard.querySelector('.study-card__back');
        firstCard.classList.add('study-card--wrong');
        firstCardBack.classList.add('study-card__back--wrong');
        firstCardFlipWrapper.classList.add('study-card__flip-wrapper--wrong');
    }
}

function moveSecondCard(id) {
    const secondCard = document.querySelector(`.study-card--${id}`);
    secondCard.classList.add('study-card--first');
}

function moveThirdCard(id) {
    const thirdCard = document.querySelector(`.study-card--${id}`);
    thirdCard.classList.add('study-card--second');
}
