import axios from 'axios';
import { useState, useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserDataContext } from '../context/UserDataContext';
import { ModalContext } from '../context/ModalContext';
import ModalEditUser from './modalContent/ModalEditUser';
import { editUserUrl, changePasswordUrl } from '../helpers/apiUrls';
import updateLocalStorage from '../helpers/updateLocalStorage';
import './EditUser.css';

const EditUser = () => {
    const location = useLocation();
    const isEditingUser = location.state?.edit === 'user';
    const isChangingPass = location.state?.edit === 'pass';
    const { userId, userName, setUserName, userEmail, setUserEmail } = useContext(UserDataContext);
    const { showModal } = useContext(ModalContext);
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({ username: userName,
                                               email: userEmail,
                                               password: '',
                                               passwordCheck: '' });
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const passwordCheckRef = useRef(null);

    const inputChangeHandler = (e) => {
      const objProp = e.target.name;
      setMessage('');
      setFormData({ ...formData,
                   [objProp]: e.target.value });
    };

    const onModifyClick = (e) => {
        e.preventDefault();

        if (isEditingUser) {
            const nameIsFilled = nameRef.current.reportValidity();
            const emailIsFilled = emailRef.current.reportValidity();

            if (!nameIsFilled || !emailIsFilled) {
                return;
            }

            if (!formData.username) {
                setMessage('Escribe un nombre de usuario');
                return;
            }

            if (!formData.email) {
                setMessage('Escribe una dirección de email');
                return;
            }

            axios.post(editUserUrl, { id: userId, username: formData.username, email: formData.email })
            .then((res) => {
                if (res.data.success) {
                    updateLocalStorage('userData', { username: formData.username, email: formData.email });
                    setUserName(formData.username);
                    setUserEmail(formData.email);
                    showModal(<ModalEditUser />);
                    return;
                }
                setMessage(res.data.message);
            })
            .catch((error) => {
                setMessage(error.message);
            });
        }

        if (isChangingPass) {
            const passIsFilled = passwordRef.current.reportValidity();
            const checkIsFilled = passwordCheckRef.current.reportValidity();

            if (!passIsFilled || !checkIsFilled) {
                return;
            }

            if (!formData.password) {
                setMessage('Escribe una contraseña');
                return;
            }

            if (formData.password.length < 6 || formData.password.length > 20) {
                setMessage('La contraseña debe tener entre 6 y 20 caracteres');
                return;
            }

            if (formData.password !== formData.passwordCheck) {
                setMessage('Las contraseñas no coinciden');
                return;
            }

            axios.post(changePasswordUrl, { id: userId, password: formData.password, passwordCheck: formData.passwordCheck })
            .then((res) => {
                if (res.data.success) {
                    showModal(<ModalEditUser />);
                    return;
                }
                setMessage(res.data.message);
            })
            .catch((error) => {
                setMessage(error.message);
            });
        }
    };

    return (
        <>
            <h1>Rellena tus datos:</h1>
            <form className="edit-user__form">
                {isEditingUser && (
                    <>
                        <label htmlFor="username" className="edit-user__label">Nombre:</label>
                        <input type="text" ref={nameRef} className="edit-user__input" name="username" value={formData.username} autoComplete="off" onChange={inputChangeHandler} required />

                        <label htmlFor="email" className="edit-user__label">Email:</label>
                        <input type="email" ref={emailRef} className="edit-user__input" name="email" value={formData.email} autoComplete="off" onChange={inputChangeHandler} required />
                    </>
                )}
                {isChangingPass && (
                    <>
                        <label htmlFor="password" className="edit-user__label">Nueva Contraseña:</label>
                        <input type="password" ref={passwordRef} className="edit-user__input" name="password" autoComplete="off" onChange={inputChangeHandler} required />

                        <label htmlFor="password-check" className="edit-user__label">Repite la contraseña:</label>
                        <input type="password" ref={passwordCheckRef} className="edit-user__input" name="passwordCheck" autoComplete="off" onChange={inputChangeHandler} required />
                    </>
                )}

                <p className="edit-user__message">{message}</p>

                <div className="buttons__wrapper">
                    <button type="submit" className="edit-user__button edit-user__button--modify" onClick={(e) => onModifyClick(e)}>Modificar</button>
                    <Link to="/panel/config" className="edit-user__button edit-user__button--cancel">Cancelar</Link>
                </div>
            </form>
        </>
    );
  };

export default EditUser;
