import React from 'react';
import './StudyCardOptions.css';

const StudyCardOptions = ({ cardData, cardIndex, onClickHandler }, ref) => {

    const onButtonClick = (isCorrectAnswer, e) => {
        const cardButtons = ref.current.childNodes;
        cardButtons.forEach((button) => {
            button.disabled = true;
        });

        const clickedOption = e.target;
        const classToAdd = isCorrectAnswer ? 'study-card__option--correct' : 'study-card__option--wrong';
        clickedOption.classList.add(classToAdd);

        onClickHandler(cardIndex, cardData.id, isCorrectAnswer);
    };

    return (
        cardData.options.map((option) => (
            <button
              key={option.option + cardData.id}
              onClick={(e) => onButtonClick(option.optionType, e)}
              className="study-card__option"
            >
            {option.option}
            </button>
        ))
    );
};

export default React.forwardRef(StudyCardOptions);
