import { memo } from 'react';
import StudyCard from './StudyCard';

const StudyCardsStack = ({ cardsToStudy, onClickHandler }) => {
    return (
        cardsToStudy.map((card, index) => {
          const cardID = `${card.id}${index}${index}`;
          return (
            <StudyCard
              key={cardID}
              cardData={card}
              onClickHandler={onClickHandler}
              cardIndex={index}
              totalCards={cardsToStudy.length}
            />
          );
        })
    );
};

export default memo(StudyCardsStack);
