import { Helmet } from 'react-helmet-async';
import { useContext, useState, useEffect, useRef } from 'react';
import { HanziDataContext } from '../context/HanziDataContext';
import { UserDataContext } from '../context/UserDataContext';
import { ModalContext } from '../context/ModalContext';
import ModalSessionExpired from '../components/modalContent/ModalSessionExpired';
import ModalRevisionCard from '../components/modalContent/ModalRevisionCard';
import ModalHanziDetails from '../components/modalContent/ModalHanziDetails';
import LearnLevels from '../components/LearnLevels';
import './Learn.css';

const Learn = () => {
    const { userHanziList, updateUserHanziList, userLevel } = useContext(UserDataContext);
    const { showModal, modalContent, setModalContent } = useContext(ModalContext);
    const { hanziListFromDatabase } = useContext(HanziDataContext);
    const lastHanziAllowedToGetInfo = userLevel * 5;
    const [isRevisionCard, setIsRevisionCard] = useState(userHanziList.length === lastHanziAllowedToGetInfo);
    const [currentHanzi, setCurrentHanzi] = useState(0);
    const totalLevels = hanziListFromDatabase.length / 5;
    const hanziToLearn = hanziListFromDatabase.find((item) => item.id === userHanziList.length + 1);
    const activeLevelBlock = useRef(null);

    useEffect(() => {
        if (activeLevelBlock.current) {
            activeLevelBlock.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        if (isRevisionCard) {
            setModalContent(<ModalRevisionCard lastHanziAllowedToGetInfo={lastHanziAllowedToGetInfo} />);
        } else {
            setModalContent(<ModalHanziDetails hanzi={hanziToLearn} nextCardHandler={nextCardHandler} />);
        }
    }, [currentHanzi]);

    const openModalHandler = () => {
        showModal(modalContent);
    };

    const nextCardHandler = async () => {
        const isLastCard = hanziToLearn.id === lastHanziAllowedToGetInfo;
        if (isLastCard) {
            setIsRevisionCard(true);
        }
        setCurrentHanzi(currentHanzi + 1);

        const hanziLearned = {
            id: hanziToLearn.id,
            hanzi: hanziToLearn.hanzi,
            isLearned: true,
            learnLevel: 0
        };

        const newUserHanziList = [...userHanziList, hanziLearned];
        const updateSuccess = await updateUserHanziList(newUserHanziList);

        if (!updateSuccess) {
            showModal(<ModalSessionExpired />);
        }
    };

    return (
        <>
            <Helmet>
                <title>Aprender caracteres - Hanzi-cards.com</title>
                <meta name="description" content="Aprende nuevos caracteres y realiza cada prueba de nivel para seguir avanzando en el estudio." />
            </Helmet>

            <h1>Aprendizaje</h1>
            <div className="learn__container">
                <LearnLevels totalLevels={totalLevels} openModalHandler={openModalHandler} activeLevelBlock={activeLevelBlock} />
            </div>
        </>
    );
};

export default Learn;
