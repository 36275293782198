import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

function usePageViews() {
    const location = useLocation();

    useEffect(
      () => {
        ReactGA.pageview(location.pathname);
      },
      [location]
    );
}

export default usePageViews;
