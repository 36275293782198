import { useEffect } from 'react';
import './ArcProgressBar.css';

const ArcProgressBar = ({ graphId, percentage, backgroundClass, strokeClass }) => {
    const barProgress = Math.floor(percentage * 2 + 5);

    useEffect(() => {
        const graph = document.getElementById(graphId);
        graph.style.strokeDasharray = `${barProgress}% 20`;
    }, []);

    return (
        <svg className="progressbar__svg" viewBox="0 0.5 10 8">
            <path d="M2 8 A 4 4 0 1 1 8 8" className={`progressbar__background ${backgroundClass}`} />
            <path id={graphId} className={`progressbar__stroke ${strokeClass}`} d="M2 8 A 4 4 0 1 1 8 8" />
        </svg>
    );
};

export default ArcProgressBar;
