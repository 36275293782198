import { useLocation } from 'react-router-dom';
import NavLogo from './NavLogo';
import NavLinks from './NavLinks';
import NavBackButton from './NavBackButton';
import './Header.css';

const Header = () => {
    const location = useLocation();
    const routes = ['/panel/learn', '/panel/study', '/panel/config', '/panel/cards'];
    let showBackButton = false;
    if (routes.includes(location.pathname)) {
        showBackButton = true;
    }

    return (
        <header className="header">
            <nav className="navbar">
                {showBackButton ? <NavBackButton /> : <NavLogo />}
                <NavLinks />
            </nav>
        </header>
    );
};

export default Header;
