import { Helmet } from 'react-helmet-async';
import { useContext } from 'react';
import { HanziDataContext } from '../context/HanziDataContext';
import { ModalContext } from '../context/ModalContext';
import ModalHanziDetails from '../components/modalContent/ModalHanziDetails';
import HanziCard from '../components/HanziCard';
import CardsLegend from '../components/CardsLegend';
import './Cards.css';

const Cards = () => {
    const { showModal } = useContext(ModalContext);
    const { hanziListFromDatabase } = useContext(HanziDataContext);

    const openModalHandler = (hanziClicked) => {
        showModal(<ModalHanziDetails currentPage="cards" hanzi={hanziClicked} openModalHandler={openModalHandler} />);
    };

    return (
        <>
            <Helmet>
                <title>Listado de caracteres - Hanzi-cards.com</title>
                <meta name="description" content="Listado de todos los caracteres aprendidos y por aprender." />
            </Helmet>

            <div className="cards__top">
                <h1>Listado</h1>
                <CardsLegend />
            </div>
            <div className="cards__container">
                {hanziListFromDatabase.map((item) => (
                    <HanziCard key={item.id} hanzi={item} currentPage="cards" onClickHandler={openModalHandler} />
                ))}
            </div>
        </>
    );
};

export default Cards;
