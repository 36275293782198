import React, { useState } from 'react';

export const UserLoginContext = React.createContext();

export const UserLoginProvider = ({ children }) => {
    const [isLogged, setIsLogged] = useState(getLoggedInitialState());
    const contextValues = { isLogged, setIsLogged };

    function getLoggedInitialState() {
        const data = JSON.parse(localStorage.getItem('userData'));
        if (!data) return false;
        return data.isLogged;
    }

    return (
        <UserLoginContext.Provider value={contextValues}>
            { children }
        </UserLoginContext.Provider>
    );
};
