import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { useContext, useState } from 'react';
import { UserDataContext } from '../context/UserDataContext';
import { UserLoginContext } from '../context/UserLoginContext';
import { HanziDataContext } from '../context/HanziDataContext';
import { ModalContext } from '../context/ModalContext';
import ModalGuestSession from '../components/modalContent/ModalGuestSession';
import getHanziListFromDatabase from '../helpers/getHanziList';
import './Home.css';

const Home = () => {
    const { setIsGuest } = useContext(UserDataContext);
    const { isLogged, setIsLogged } = useContext(UserLoginContext);
    const { setHanziListFromDatabase } = useContext(HanziDataContext);
    const { showModal } = useContext(ModalContext);
    const [loading, setLoading] = useState(false);

    const startSession = () => {
        setLoading(true);
        getHanziListFromDatabase()
        .then((hanziList) => {
            setHanziListFromDatabase(hanziList);
            setIsGuest(true);
            setIsLogged(true);
            showModal(<ModalGuestSession />);
        });
    };

    const loadingAnimation = <img className="home__button-loading" src="/img/loading.svg" alt="" />;

    return (
        <main>
            {isLogged && <Redirect to="/panel" />}

            <Helmet>
                <title>Aprende los caracteres chinos más usados - Hanzi-cards.com</title>
                <meta name="description" content="Acelera tu progreso en el estudio del idioma chino aprendiendo primero los caracteres usados con más frecuencia." />
                <link rel="canonical" href="https://www.hanzi-cards.com" />
            </Helmet>

            <section className="home__header">
                <div className="home__slogan">
                    <h1 className="home__title">APRENDE LOS CARACTERES CHINOS <span>MÁS USADOS</span></h1>
                    <button onClick={startSession} className="home__button-start">
                        {loading ? loadingAnimation : 'Comienza ahora'}
                    </button>
                    <span className="home__text-small">No es necesario registro</span>
                </div>
                <div className="home__preview-wraper">
                    <img className="home__preview" src="/img/app-preview.png" alt="Previsualización de la aplicación Hanzi Cards" />
                </div>
            </section>
            <section className="features">
                <h2>HanziCards te proporciona todo lo que necesitas para aprender caracteres de la manera más eficiente</h2>
                <div className="features__wrapper">
                    <div className="feature">
                        <img src="/img/rocket.png" alt="Progresa más rápido" />
                        <div>
                            <h3>Progresa más rápido</h3>
                            <p>Acelera tu progreso aprendiendo los caracteres chinos que son usados con más frecuencia.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <img src="/img/learn.png" alt="Apréndelo todo de cada caracter" />
                        <div>
                            <h3>Apréndelo todo</h3>
                            <p>Escritura, transcripción a pinyin, significado, y pronunciación en audio de cada caracter.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <img src="/img/levels.png" alt="Supera los niveles" />
                        <div>
                            <h3>Supera los niveles</h3>
                            <p>Aprendizaje progresivo por niveles, supera cada prueba de nivel para seguir progresando.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <img src="/img/deck.png" alt="Repasa lo aprendido" />
                        <div>
                            <h3>Repasa lo aprendido</h3>
                            <p>Puedes repasar todas las tarjetas aprendidas por orden de frecuencia o de forma aleatoria.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <img src="/img/responsive.png" alt="En cualquier lugar" />
                        <div>
                            <h3>En cualquier lugar</h3>
                            <p>Sigue aprendiendo en cualquier dispositivo, la app se puede usar en móvil, tablet o pc.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <img src="/img/precise.png" alt="Aprendizaje preciso" />
                        <div>
                            <h3>Aprendizaje preciso</h3>
                            <p>Cada caracter tiene su propia barra de nivel para que puedas comprobar cada uno individualmente.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="challenge">
                <div className="challenge__wrapper">
                    <h2 className="challenge__title">¡Enfréntate al reto de aprender los caracteres chinos!</h2>
                    <div className="challenge__subsection">
                        <div className="challenge__content">
                            <h3>No esperes a saber el idioma para empezar a aprender caracteres</h3>
                            <p>Empezar a aprender chino usando pinyin sin caracteres puede parecer más sencillo, pero cuando llegue el momento de estudiar los caracteres te costará más, o incluso puede que pospongas durante años el aprendizaje de los caracteres por pereza.</p>
                            <p>Lo mejor es comenzar cuanto antes a entrenar la vista para reconocer los caracteres chinos visualmente. </p>
                        </div>
                        <img className="challenge__image" src="/img/girl-books.svg" alt="" />
                    </div>
                    <div className="challenge__subsection challenge__subsection--reverse">
                        <div className="challenge__content">
                            <h3>Empieza a aprender los caracteres chinos desde el primer día</h3>
                            <p>Aunque en un principio no sepas o recuerdes su significado o su lectura, en poco tiempo sabrás identificar los caracteres más comunes, y cuando aprendas a leerlos y su significado, tu progreso será mucho más rápido.</p>
                            <p>Dedica desde el principio parte de tu tiempo de estudio a visualizar caracteres y a reconocer sus formas y sus trazos, ya verás como en cuanto empieces ya estarás diciendo &quot;<i>¡este caracter lo reconozco, lo he visto antes!</i>&quot;</p>
                        </div>
                        <img className="challenge__image" src="/img/girl-studying.svg" alt="" />
                    </div>
                </div>
            </section>
            <section className="methodology">
                <h2 className="methodology__title">Estudio de caracteres basado en la frecuencia de uso</h2>
                <div className="methodology__subsection">
                    <div className="methodology__content">
                        <h3>Aprende primero los caracteres chinos usados con más frecuencia</h3>
                        <p>El primer caracter que aprenderás será el caracter más usado en el idioma chino, después el segundo más usado, después el tercero más usado, y así sucesivamente.</p>
                        <p>Desde el mismo momento en que aprendas cada caracter, prácticamente podrás reconocerlo en cualquier texto, ya que su uso será muy frecuente, y de esta forma podrás practicar su lectura fácilmente.</p>
                    </div>
                    <img className="methodology__image" src="/img/sequence.png" alt="Orden de aprendizaje secuencial" />
                </div>
                <div className="methodology__subsection methodology__subsection--reverse">
                    <img className="methodology__image" src="/img/doubt.png" alt="Cómo saber la frecuencia de uso" />
                    <div className="methodology__content">
                        <h3>¿Cómo se sabe cuáles son los caracteres chinos más frecuentes?</h3>
                        <p>Para llegar a saber cuáles son los caracteres chinos usados con más frecuencia se han analizado miles de libros, documentos y textos chinos a través de internet.</p>
                        <p>La lista de caracteres más usados la confecciona una entidad china llamada Jun Da, que ofrece los datos públicamente y pueden ser consultados por cualquier persona.</p>
                    </div>
                </div>
            </section>
            <section className="steps">
                <div className="steps__wrapper">
                    <h2 className="steps__title">Usar HanziCards para aprender caracteres es muy sencillo</h2>
                    <div className="steps__cards">
                        <div className="steps__card steps__arrow--bottom">
                            <h3 className="steps__card-title steps__card-title--one">1. Comienza</h3>
                            <p>Inicia sesión como invitado o registra una cuenta</p>
                        </div>
                        <div className="steps__card steps__arrow--top">
                            <h3 className="steps__card-title steps__card-title--two">2. Aprende</h3>
                            <p>Estudia los primeros caracteres y su significado</p>
                        </div>
                        <div className="steps__card steps__arrow--bottom">
                            <h3 className="steps__card-title steps__card-title--three">3. Sube de nivel</h3>
                            <p>Supera los test para seguir aprendiendo caracteres</p>
                        </div>
                        <div className="steps__card">
                            <h3 className="steps__card-title steps__card-title--four">4. Repasa</h3>
                            <p>Pon a prueba los caracteres que ya aprendiste</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Home;
