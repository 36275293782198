import { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import { FcGraduationCap, FcReading, FcFilingCabinet, FcSettings, FcMenu } from 'react-icons/fc';
import { UserDataContext } from '../context/UserDataContext';
import { UserLoginContext } from '../context/UserLoginContext';
import './NavLinks.css';

const NavLinks = () => {
    const { setUserName, setUserLevel, setUserHanziList, setUserOptions } = useContext(UserDataContext);
    const { isLogged, setIsLogged } = useContext(UserLoginContext);
    const [menuVisible, setMenuVisible] = useState(false);
    const loggedLinks = useRef(null);

    useEffect(() => {
        if (menuVisible) {
            loggedLinks.current.classList.add('nav__logged-links--open');
        }
    }, [menuVisible]);

    const closeMenu = () => {
        toggleMenuVisibility();
    };

    const closeSession = () => {
        toggleMenuVisibility();
        window.localStorage.clear();
        setUserName('');
        setUserHanziList([]);
        setIsLogged(false);
        setUserOptions({});
        setUserLevel(1);
    };

    const toggleMenuVisibility = () => {
        if (menuVisible) {
            loggedLinks.current.classList.remove('nav__logged-links--open');
            setTimeout(() => {
                setMenuVisible(false);
            }, 200); // 200 es la duración de la animación de fadeout
            return;
        }

        setMenuVisible(!menuVisible);
    };

    const notLoggedNavLinks = (
        <ul className="nav__links">
            <li>
                <Link to="/login" className="nav__link nav__link--login">Inicia sesión</Link>
            </li>
        </ul>
    );

    const loggedNavLinks = (
        <div className="nav__profile">
            <button onClick={toggleMenuVisibility} aria-label="Opciones">
                <FcMenu className="nav__profile-icon" />
            </button>
            {menuVisible && (
                <>
                    <div onClick={toggleMenuVisibility} className="nav__logged-links--background" />
                    <ul ref={loggedLinks} className="nav__logged-links">
                        <li>
                            <Link to="/panel/learn" className="nav__link-button" onClick={closeMenu}>
                                <FcGraduationCap className="nav__link-icon" />
                                <span className="nav__link-text">Aprender</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/panel/study" className="nav__link-button" onClick={closeMenu}>
                                <FcReading className="nav__link-icon" />
                                <span className="nav__link-text">Estudiar</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/panel/cards" className="nav__link-button" onClick={closeMenu}>
                                <FcFilingCabinet className="nav__link-icon" />
                                <span className="nav__link-text">Listado</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/panel/config" className="nav__link-button" onClick={closeMenu}>
                                <FcSettings className="nav__link-icon nav__link-icon--config" />
                                <span className="nav__link-text">Opciones</span>
                            </Link>
                        </li>
                        <li>
                            <button className="nav__link-button" onClick={closeSession}>
                                <BiLogOut className="nav__link-icon nav__link-icon--logout" />
                                <span className="nav__link-text">Cerrar Sesíón</span>
                            </button>
                        </li>
                    </ul>
                </>
            )}
        </div>
    );

    return (
        <>
            {isLogged ? loggedNavLinks : notLoggedNavLinks}
        </>
    );

};

export default NavLinks;
