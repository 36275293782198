import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import './CardsLegend.css';

const CardsLegend = () => (
    <div className="legend">
        <FaThumbsDown className="legend__icon legend__icon--bad" />
        <FaThumbsUp className="legend__icon legend__icon--good" />
    </div>
);

export default CardsLegend;
