import { useContext } from 'react';
import { BiPlayCircle } from 'react-icons/bi';
import { AiFillCaretDown } from 'react-icons/ai';
import { GrNext } from 'react-icons/gr';
import { UserDataContext } from '../context/UserDataContext';
import { HanziDataContext } from '../context/HanziDataContext';
import playChineseVoice from '../helpers/playChineseVoice';
import './HanziDetails.css';

const HanziDetails = ({ hanzi, nextCardHandler, currentPage, openModalHandler }) => {
    const { userHanziList } = useContext(UserDataContext);
    const { hanziListFromDatabase } = useContext(HanziDataContext);
    const currentHanzi = userHanziList.find((item) => (hanzi.id === item.id));
    const isFirstHanzi = hanzi.id === 1;
    const isLastHanzi = hanzi.id === userHanziList.length;
    const learnPercentage = (currentHanzi?.learnLevel + 5) * 10;

    const nextCard = () => {
        const nextHanzi = hanziListFromDatabase.find((item) => (hanzi.id + 1 === item.id));
        openModalHandler(nextHanzi);
    };

    const previousCard = () => {
        const nextHanzi = hanziListFromDatabase.find((item) => (hanzi.id - 1 === item.id));
        openModalHandler(nextHanzi);
    };

    return (
        <>
            <div className="hanzi-details__id">{hanzi.id}</div>
            <div className="hanzi-details__top">
                <div className="hanzi-details__primary">
                    <h2>Caracter</h2>
                    <p>{hanzi.hanzi}</p>
                </div>
                <div className="hanzi-details__pronunciation">
                    <h2>Pronunciación</h2>
                    <p>{hanzi.pinyin}</p>
                    <button onClick={() => playChineseVoice(hanzi.hanzi)} className="hanzi-details__listen-btn">
                        <BiPlayCircle />
                        <span>Escuchar</span>
                    </button>
                </div>
            </div>
            <div className="hanzi-details__meaning">
                <h2>Significado:</h2>
                <p>{hanzi.meaning}</p>
            </div>
            {currentPage === 'cards' && (
                <>
                    <div className="hanzi-details__progress">
                        <h2>Aprendizaje:</h2>
                        <div className="hanzi-details__legend">
                            <div className="hanzi-details__legend-icon-wrapper">
                                <span style={{ left: `${learnPercentage}%` }} className="hanzi-details__legend-icon">
                                    <AiFillCaretDown />
                                </span>
                            </div>
                        </div>
                    </div>
                    {!isFirstHanzi && (
                        <GrNext className="hanzi-details__arrow hanzi-details__arrow--left" onClick={previousCard} />
                    )}
                    {!isLastHanzi && (
                    <GrNext className="hanzi-details__arrow hanzi-details__arrow--right" onClick={nextCard} />
                    )}
                </>
            )}
            {currentPage !== 'cards' && (
                <div className="hanzi-details__next-button-wrapper">
                    <button className="hanzi-details__next-button" onClick={nextCardHandler}>OK, Aprendido</button>
                </div>
            )}
        </>
    );
};

export default HanziDetails;
