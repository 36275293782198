import { Helmet } from 'react-helmet-async';
import { useLocation, useHistory } from 'react-router-dom';
import React, { useContext, useCallback, useState, useEffect } from 'react';
import { HanziDataContext } from '../context/HanziDataContext';
import { UserDataContext } from '../context/UserDataContext';
import { ModalContext } from '../context/ModalContext';
import ModalStudyCompleted from '../components/modalContent/ModalStudyCompleted';
import ModalSessionExpired from '../components/modalContent/ModalSessionExpired';
import ModalStartLevelTest from '../components/modalContent/ModalStartLevelTest';
import moveCards from '../helpers/moveCards';
import StudyCardsStack from '../components/StudyCardsStack';
import StudyCounters from '../components/StudyCounters';
import createCardsToStudy from '../helpers/createCardsToStudy';
import checkStudyScore from '../helpers/checkStudyScore';
import './Study.css';

const Study = () => {
    const location = useLocation();
    const history = useHistory();
    const isFromLearn = location.state?.from === 'learn';
    const { hanziListFromDatabase } = useContext(HanziDataContext);
    const { userHanziList, userLevel, updateUserHanziList, setUserLevelUp, userOptions } = useContext(UserDataContext);
    const { showModal } = useContext(ModalContext);
    const [cardsToStudy, setCardsToStudy] = useState(null);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [wrongAnswers, setWrongAnswers] = useState(0);
    const [studyCompletedMessage, setStudyCompletedMessage] = useState(null);
    const [currentCardNumber, setcurrentCardNumber] = useState(1);

    useEffect(() => {
        const hanziToStudy = isFromLearn ? [...location.state.hanziToStudy] : userHanziList;
        const { shuffleCards } = userOptions;
        const cards = createCardsToStudy(hanziToStudy, hanziListFromDatabase, isFromLearn, shuffleCards);
        setCardsToStudy(cards);
        if (isFromLearn) {
            showModal(<ModalStartLevelTest />);
        }
    }, []);

    useEffect(() => {
        const totalCardsToStudy = cardsToStudy?.length;
        const isLastCard = (currentCardNumber - 1) === totalCardsToStudy;
        if (isLastCard) {
            const { title, message, levelUp, studySuccess } = checkStudyScore(isFromLearn, totalCardsToStudy, correctAnswers, userLevel);
            setStudyCompletedMessage({ title, message, levelUp, studySuccess });
            if (levelUp) setUserLevelUp();
        }
    }, [currentCardNumber]);

    useEffect(() => {
        if (studyCompletedMessage) {
            setTimeout(() => {
                showModal(<ModalStudyCompleted studyCompletedMessage={studyCompletedMessage} studyAgain={studyAgain} isFromLearn={isFromLearn} />);
            }, 800);
        }
    }, [studyCompletedMessage]);

    const setHanziLearnLevel = async (cardId, isCorrectAnswer) => {
        const newUserHanziList = userHanziList;
        const userHanzi = newUserHanziList.find((item) => item.id === cardId);
        const currentLearnLevel = userHanzi.learnLevel;

        if (!isCorrectAnswer && currentLearnLevel <= 5 && currentLearnLevel >= -4) {
            userHanzi.learnLevel = currentLearnLevel - 1;
        }

        if (isCorrectAnswer && currentLearnLevel <= 4 && currentLearnLevel >= -5) {
            userHanzi.learnLevel = currentLearnLevel + 1;
        }

        const updateSuccess = await updateUserHanziList(newUserHanziList);

        if (!updateSuccess) {
            showModal(<ModalSessionExpired />);
        }
     };

    // Uso prevState para obtener el valor correcto de la variable de estado, ya que la
    // función onAnswerHandler está usando useCallback y no pasa al componente hijo el valor
    // actualizado de la variable de estado, por lo que se quedaría siempre en el valor inicial.
    const checkAnswer = (isCorrectAnswer) => {
        if (isCorrectAnswer) {
            setCorrectAnswers((prevState) => prevState + 1);
        } else {
            setWrongAnswers((prevState) => prevState + 1);
        }
        setcurrentCardNumber((prevState) => prevState + 1);
    };

    const onAnswerHandler = useCallback((cardIndex, cardId, isCorrectAnswer) => {
        moveCards(cardIndex, isCorrectAnswer, cardsToStudy.length);
        setHanziLearnLevel(cardId, isCorrectAnswer);
        checkAnswer(isCorrectAnswer);
    }, [cardsToStudy]);


    const studyAgain = () => {
        history.push('/panel/empty');
        setTimeout(() => { // Chapuza, pero no he encontrado otra forma de recargar el componente
            history.push('/panel/study', { from: location.state?.from, hanziToStudy: location.state?.hanziToStudy });
        }, 1);
    };

    const noCardsMessageContent = (
        <p className="study__no-cards-message">
            Todavía no has aprendido ningún caracter para estudiar.
            Ve a la sección Aprender y comienza aprendiendo algunos.
        </p>
    );

    const remainCards = cardsToStudy?.length + 1 - currentCardNumber;
    const existAtLeastOneCard = cardsToStudy?.length !== 0;

    return (
        <>
            <Helmet>
                <title>Estudio de caracteres - Hanzi-cards.com</title>
                <meta name="description" content="Estudia los caracteres que ya has aprendido para mejorar el nivel de aprendizaje." />
            </Helmet>

            <div className="study__top">
                <h1>Estudio</h1>
                <StudyCounters correctAnswers={correctAnswers} wrongAnswers={wrongAnswers} remainCards={remainCards} />
            </div>

            {!existAtLeastOneCard && noCardsMessageContent}

            {cardsToStudy && existAtLeastOneCard && (
                <div className="study__container">
                    <StudyCardsStack cardsToStudy={cardsToStudy} onClickHandler={onAnswerHandler} />
                </div>
            )}

        </>
    );
};

export default Study;
