const checkStudyScore = (isFromLearn, totalCardsToStudy, correctAnswers, userLevel) => {
    const minimunCorrectAnswersToPass = isFromLearn ? 20 : totalCardsToStudy / 2;
    const studyEqual = correctAnswers === minimunCorrectAnswersToPass;
    let studySuccess = correctAnswers > minimunCorrectAnswersToPass;
    let levelUp = false;
    let title;
    let message;

    if (isFromLearn) {
        studySuccess = correctAnswers >= minimunCorrectAnswersToPass;
        if (studySuccess) {
            title = '¡Muy bien!';
            message = `Has superado la cantidad mínima de aciertos, ¡Has pasado al nivel ${userLevel + 1}!`;
            levelUp = true;
        } else {
            title = '¡Ups, vaya!';
            message = 'No has superado la cantidad mínima de aciertos, repasa los caracteres aprendidos en este nivel y repite de nuevo la prueba para pasar al siguiente nivel.';
        }

        return { title, message, levelUp, studySuccess };
    }

    if (studySuccess) {
        title = '¡Muy bien!';
        message = 'Has tenido más aciertos que fallos, ve a la sección Aprender para seguir aprendiendo nuevos caracteres o repite de nuevo el estudio para seguir repasando.';
    } else if (studyEqual) {
        title = '¡Ups, vaya!';
        message = 'Has tenido el mismo número de aciertos que de fallos. No está mal, pero puedes repasar los caracteres que menos recuerdes y repetir el estudio para obtener mejor puntuación.';
    } else {
        title = '¡Ups, vaya!';
        message = 'Has tenido más fallos que aciertos, repasa los caracteres o vuelve a repetir el estudio para intentar obtener una mejor puntuación.';
    }

    return { title, message, levelUp, studySuccess };
};

export default checkStudyScore;
