import React, { useState } from 'react';

export const ModalContext = React.createContext();

export const ModalContextProvider = ({ children }) => {
    const [modalState, setModalState] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const closeModalHandler = () => setModalState(false);

    const showModal = (content) => {
        setModalContent(content);
        setModalState(true);
    };

    const contextValues = { modalState, setModalState, modalContent, setModalContent, showModal, closeModalHandler };

    return (
        <ModalContext.Provider value={contextValues}>
            {children}
        </ModalContext.Provider>
    );
};
