import './Footer.css';

const Footer = () => (
    <footer className="footer">
        <div className="footer__wrapper">
            <span>Copyright © 2021 Hanzi-cards.com</span><span>Todos los derechos reservados</span>
        </div>
    </footer>
);

export default Footer;
