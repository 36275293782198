import React, { useState } from 'react';

export const HanziDataContext = React.createContext();

export const HanziDataContextProvider = ({ children }) => {
    const hanziListInitialState = JSON.parse(localStorage.getItem('hanziList'))
        ? JSON.parse(localStorage.getItem('hanziList'))
        : [];

    const [hanziListFromDatabase, setHanziListFromDatabase] = useState(hanziListInitialState);
    const contextValues = { hanziListFromDatabase, setHanziListFromDatabase };

    return (
        <HanziDataContext.Provider value={contextValues}>
            { children }
        </HanziDataContext.Provider>
    );
};
