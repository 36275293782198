import axios from 'axios';
import { sendPasswordTokenUrl, checkPasswordTokenUrl, resetPasswordUrl } from './apiUrls';

export const sendPasswordToken = async (email) => {
    const response = await axios.post(sendPasswordTokenUrl, { email })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });

    return response;
};


export const checkPasswordToken = async (email, token) => {
    const response = await axios.post(checkPasswordTokenUrl, { email, token })

      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });

    return response;
};

export const resetPassword = async (email, token, password, passwordCheck) => {
    const response = await axios.post(resetPasswordUrl, { email, token, password, passwordCheck })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });

    return response;
};
