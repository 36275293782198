import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ModalContext } from '../../context/ModalContext';

const ModalCreateUser = () => {
    const { setModalState } = useContext(ModalContext);

    const closeModal = () => {
        setModalState(false);
    };

    return (
        <div className="modal__content modal__create-user">
            <h2>¡Usuario creado!</h2>
            <p>Tu cuenta de usuario se ha creado correctamente, a partir de ahora todo tu progreso estará sincronizado en la nube.</p>
            <p>Puedes usar el email y contraseña que has proporcionado para iniciar sesión desde cualquier dispositivo y continuar el progreso donde lo dejaste.</p>
            <div className="buttons__wrapper">
                <Link to="/panel" onClick={closeModal} className="modal__create-user-button">Perfecto</Link>
            </div>
        </div>
    );
};

export default ModalCreateUser;
