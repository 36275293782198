import { Link } from 'react-router-dom';
import { FcGraduationCap, FcReading, FcFilingCabinet, FcSettings } from 'react-icons/fc';
import './PanelMenu.css';

const PanelMenu = () => (
    <>
        <div className="menu-buttons__container">
            <Link to="/panel/learn" className="menu-button menu-button--learn">
                <div className="menu-button__icon"><FcGraduationCap /></div>
                <span className="menu-button__title">Aprender</span>
                <span className="menu-button__subtitle">nuevos caracteres</span>
            </Link>
            <Link to="/panel/study" className="menu-button menu-button--study">
                <div className="menu-button__icon"><FcReading /></div>
                <span className="menu-button__title">Estudiar</span>
                <span className="menu-button__subtitle">los ya aprendidos</span>
            </Link>
            <Link to="/panel/cards" className="menu-button menu-button--hanzi">
                <div className="menu-button__icon"><FcFilingCabinet /></div>
                <span className="menu-button__title">Listado</span>
                <span className="menu-button__subtitle">de caracteres</span>
            </Link>
            <Link to="/panel/config" className="menu-button menu-button--config">
                <div className="menu-button__icon"><FcSettings /></div>
                <span className="menu-button__title">Opciones</span>
                <span className="menu-button__subtitle">de perfil</span>
            </Link>
        </div>
    </>
);

export default PanelMenu;
