import axios from 'axios';
import ReactGA from 'react-ga';
import { useState, useRef, useContext } from 'react';
import { UserDataContext } from '../context/UserDataContext';
import { createUserUrl } from '../helpers/apiUrls';
import { ModalContext } from '../context/ModalContext';
import ModalCreateUser from './modalContent/ModalCreateUser';
import updateLocalStorage from '../helpers/updateLocalStorage';
import './CreateUser.css';

const CreateUser = () => {
    const { userId, setIsGuest, setUserName, setUserEmail, userOptions, userHanziList, setToken } = useContext(UserDataContext);
    const { showModal } = useContext(ModalContext);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ username: '',
                                               email: '',
                                               password: '',
                                               passwordCheck: '',
                                               id: userId,
                                               userOptions: JSON.stringify(userOptions),
                                               userHanziList: JSON.stringify(userHanziList),
                                               key: 'hanzicards' });
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const passwordCheckRef = useRef(null);

    const inputChangeHandler = (e) => {
      const objProp = e.target.name;
      setMessage('');
      setFormData({ ...formData,
                   [objProp]: e.target.value });
    };

    const onRegisterClick = (e) => {
        e.preventDefault();
        const nameIsFilled = nameRef.current.reportValidity();
        const emailIsFilled = emailRef.current.reportValidity();
        const passIsFilled = passwordRef.current.reportValidity();
        const checkIsFilled = passwordCheckRef.current.reportValidity();

        if (!nameIsFilled || !emailIsFilled || !passIsFilled || !checkIsFilled) {
            return;
        }

        if (!formData.username) {
            setMessage('Escribe un nombre de usuario');
            return;
        }

        if (!formData.email) {
            setMessage('Escribe una dirección de email');
            return;
        }

        if (!formData.password) {
            setMessage('Escribe una contraseña');
            return;
        }

        if (formData.password.length < 6 || formData.password.length > 20) {
            setMessage('La contraseña debe tener entre 6 y 20 caracteres');
            return;
        }

        if (formData.password !== formData.passwordCheck) {
            setMessage('Las contraseñas no coinciden');
            return;
        }

        setLoading(true);
        axios.post(createUserUrl, formData)
            .then((response) => {
                if (response.data.success) {
                    setIsGuest(false);
                    setUserName(formData.username);
                    setUserEmail(formData.email);
                    setToken(response.data.token);
                    updateLocalStorage('userData', { isGuest: false,
                                                     username: formData.username,
                                                     email: formData.email,
                                                     token: response.data.token
                                                    });
                    showModal(<ModalCreateUser />);
                    ReactGA.event({
                        category: 'General',
                        action: 'Creada cuenta de usuario'
                    });
                    return;
                }
                setMessage(response.data.message);
                setLoading(false);
            })
            .catch((error) => {
                setMessage(error.message);
                setLoading(false);
            });
    };

    const loadingAnimation = <img className="home__button-loading" src="/img/loading.svg" alt="" />;

    return (
        <>
            <h1>Rellena tus datos:</h1>
            <form className="create-user__form">
                <label htmlFor="username" className="create-user__label">Nombre:</label>
                <input type="text" ref={nameRef} className="create-user__input" name="username" autoComplete="off" onChange={inputChangeHandler} required />

                <label htmlFor="email" className="create-user__label">Email:</label>
                <input type="email" ref={emailRef} className="create-user__input" name="email" autoComplete="off" onChange={inputChangeHandler} required />

                <label htmlFor="password" className="create-user__label">Contraseña:</label>
                <input type="password" ref={passwordRef} className="create-user__input" name="password" autoComplete="off" onChange={inputChangeHandler} required />

                <label htmlFor="password-check" className="create-user__label">Repite la contraseña:</label>
                <input type="password" ref={passwordCheckRef} className="create-user__input" name="passwordCheck" autoComplete="off" onChange={inputChangeHandler} required />

                <p className="create-user__message">{message}</p>

                <button type="submit" className="create-user__button" onClick={(e) => onRegisterClick(e)}>
                    {loading ? loadingAnimation : 'Crear usuario'}
                </button>
            </form>
        </>
    );
  };

export default CreateUser;
