import { useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';

const ModalStartLevelTest = () => {
    const { setModalState } = useContext(ModalContext);

    const closeModal = () => {
        setModalState(false);
    };

    return (
        <div className="modal__content modal__study">
            <h2 className="modal__study-title">¡Prueba de nivel!</h2>
            <p>Vas a realizar una prueba de nivel.</p>
            <p>La prueba de nivel se compone de 30 tarjetas en las que se repiten de forma secuencial los caracteres que has aprendido en este nivel.</p>
            <p>Para pasar al siguiente nivel debes responder correctamente al menos 20 de las 30 tarjetas. ¡Buena suerte!</p>
            <button className="modal__study-button modal__study-button--ok" onClick={closeModal}>Entendido</button>
        </div>
    );
};

export default ModalStartLevelTest;
