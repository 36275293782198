import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserLoginContext } from '../context/UserLoginContext';
import Cards from '../sections/Cards';
import Study from '../sections/Study';
import Learn from '../sections/Learn';
import Config from '../sections/Config';
import Graphs from '../components/Graphs';
import PanelMenu from '../components/PanelMenu';
import CreateUser from '../components/CreateUser';
import EditUser from '../components/EditUser';
import './Panel.css';

const PanelHome = () => (
    <>
        <Helmet>
            <title>Panel - Hanzi-cards.com</title>
            <meta name="description" content="Panel de usuario." />
        </Helmet>

        <h1>Panel</h1>
        <div className="panel__container">
            <Graphs />
            <PanelMenu />
        </div>
    </>
);

const Panel = () => {
    const { isLogged } = useContext(UserLoginContext);

    return (
        <main>
            <Switch>
                <Route path="/panel/empty" />
                <Route path="/panel/cards" component={Cards} />
                <Route path="/panel/learn" component={Learn} />
                <Route path="/panel/study" component={Study} />
                <Route path="/panel/config/create" component={CreateUser} />
                <Route path="/panel/config/edit" component={EditUser} />
                <Route path="/panel/config" component={Config} />
                <Route path="/panel" component={PanelHome} />
            </Switch>

            {!isLogged && <Redirect to="/" />}
        </main>
    );
};

export default Panel;
