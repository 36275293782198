import { useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';
import RevisionDetails from '../RevisionDetails';

const ModalRevisionCard = ({ lastHanziAllowedToGetInfo }) => {
    const { setModalState } = useContext(ModalContext);

    const closeModal = () => {
        setModalState(false);
    };

    return (
        <>
            <div className="modal__content modal__revision">
                <RevisionDetails lastHanziAllowedToGetInfo={lastHanziAllowedToGetInfo} closeModalHandler={closeModal} />
            </div>
            <button className="modal__button-close" onClick={closeModal}>Hacer más tarde</button>
        </>
    );
};

export default ModalRevisionCard;
