import { useState, useRef, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { ModalContext } from '../context/ModalContext';
import ModalResetPassword from './modalContent/ModalResetPassword';
import { sendPasswordToken, checkPasswordToken, resetPassword } from '../helpers/resetPassword';
import './ResetPassword.css';

const ResetPassword = () => {
    const { showModal } = useContext(ModalContext);
    const [message, setMessage] = useState('');
    const [emailIsPresent, setEmailIsPresent] = useState(false);
    const [tokenIsPresent, setTokenIsPresent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ email: '',
                                               token: '',
                                               password: '',
                                               passwordCheck: '' });
    const emailRef = useRef(null);
    const tokenRef = useRef(null);
    const passwordRef = useRef(null);
    const passwordCheckRef = useRef(null);

    const inputChangeHandler = (e) => {
      const objProp = e.target.name;
      setMessage('');
      setFormData({ ...formData,
                   [objProp]: e.target.value });
    };

    const onSendEmail = (e) => {
        e.preventDefault();
        const emailIsFilled = emailRef.current.reportValidity();

        if (!emailIsFilled) {
            return;
        }

        if (!formData.email) {
            setMessage('Escribe una dirección de email');
            return;
        }

        setLoading(true);
        sendPasswordToken(formData.email)
            .then((res) => {
                if (!res.success) {
                    setMessage(res.message);
                } else {
                    setEmailIsPresent(true);
                }
                setLoading(false);
            });
    };

    const onCheckToken = (e) => {
        e.preventDefault();
        const tokenIsFilled = tokenRef.current.reportValidity();

        if (!tokenIsFilled) {
            return;
        }

        if (!formData.token) {
            setMessage('Introduce el código que te hemos enviado a tu email');
            return;
        }

        setLoading(true);
        checkPasswordToken(formData.email, formData.token)
            .then((res) => {
                if (!res.success) {
                    setMessage(res.message);
                } else {
                    setTokenIsPresent(true);
                }
                setLoading(false);
            });
    };

    const onResetPassword = (e) => {
        e.preventDefault();
        const passIsFilled = passwordRef.current.reportValidity();
        const checkIsFilled = passwordCheckRef.current.reportValidity();

        if (!passIsFilled || !checkIsFilled) {
            return;
        }

        if (!formData.password) {
            setMessage('Escribe una contraseña');
            return;
        }

        if (formData.password.length < 6 || formData.password.length > 20) {
            setMessage('La contraseña debe tener entre 6 y 20 caracteres');
            return;
        }

        if (formData.password !== formData.passwordCheck) {
            setMessage('Las contraseñas no coinciden');
            return;
        }

        setLoading(true);
        resetPassword(formData.email, formData.token, formData.password, formData.passwordCheck)
            .then((res) => {
                if (!res.success) {
                    setMessage(res.message);
                } else {
                    showModal(<ModalResetPassword />);
                }
                setLoading(false);
            });
    };

    const loadingAnimation = <img className="reset-password__button-loading" src="/img/loading.svg" alt="" />;

    const ButtonSendToken = () => (
        <button type="submit" className="reset-password__button" onClick={(e) => onSendEmail(e)}>
            {loading ? loadingAnimation : 'Enviar email'}
        </button>
    );

    const ButtonCheckToken = () => (
        <button type="submit" className="reset-password__button" onClick={(e) => onCheckToken(e)}>
            {loading ? loadingAnimation : 'Aceptar'}
        </button>
    );

    const ButtonResetPassword = () => (
        <button type="submit" className="reset-password__button" onClick={(e) => onResetPassword(e)}>
            {loading ? loadingAnimation : 'Cambiar contraseña'}
        </button>
    );

    return (
        <main>
            <Helmet>
                <title>Restablecer contraseña - Hanzi-cards.com</title>
                <meta name="description" content="Si has olvidado tu contraseña, te enviaremos un email con un código de seguridad para que puedas restablecerla." />
            </Helmet>

            <div className="reset-password__container">
                <h1>Rellena tus datos:</h1>
                <form className="reset-password__form">
                    {!emailIsPresent && !tokenIsPresent && (
                        <>
                        <label htmlFor="email" className="reset-password__label">Introduce tu email:</label>
                        <input type="email" ref={emailRef} className="reset-password__input" name="email" autoComplete="off" value={formData.email} onChange={inputChangeHandler} required />
                        <ButtonSendToken />
                        </>
                    )}

                    {emailIsPresent && !tokenIsPresent && (
                        <>
                        <label htmlFor="token" className="reset-password__label">Introduce el código de seguridad enviado al email:</label>
                        <input type="text" ref={tokenRef} className="reset-password__input" name="token" autoComplete="off" value={formData.token} onChange={inputChangeHandler} />
                        <ButtonCheckToken />
                        </>
                    )}

                    {emailIsPresent && tokenIsPresent && (
                        <>
                        <label htmlFor="password" className="reset-password__label">Nueva contraseña:</label>
                        <input type="password" ref={passwordRef} className="reset-password__input" name="password" autoComplete="off" onChange={inputChangeHandler} />
                        <label htmlFor="password-check" className="reset-password__label">Repite la nueva contraseña:</label>
                        <input type="password" ref={passwordCheckRef} className="reset-password__input" name="passwordCheck" autoComplete="off" onChange={inputChangeHandler} />
                        <ButtonResetPassword />
                        </>
                    )}

                    <p className="reset-password__message">{message}</p>

                </form>
            </div>
        </main>
    );
  };

export default ResetPassword;
