import { Helmet } from 'react-helmet-async';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserDataContext } from '../context/UserDataContext';
import { ModalContext } from '../context/ModalContext';
import ModalSessionExpired from '../components/modalContent/ModalSessionExpired';
import ModalResetProgress from '../components/modalContent/ModalResetProgress';
import CheckBox from '../components/CheckBox';
import './Config.css';

const Config = () => {
    const { showModal } = useContext(ModalContext);
    const { isGuest, userOptions, updateUserOptions } = useContext(UserDataContext);
    const [checkBox1State, setCheckBox1State] = useState(userOptions.showMeanings);
    const [checkBox2State, setCheckBox2State] = useState(userOptions.shuffleCards);

    const openModalResetProgress = () => {
        showModal(<ModalResetProgress />);
    };

    const toggleSwitchHandler = async (e) => {
        const checkBoxId = e.target.id;
        let updateSuccess;
        if (checkBoxId === 'showHideMeanings') {
            const newCheckBoxState = !checkBox1State;
            setCheckBox1State(newCheckBoxState);
            updateSuccess = await updateUserOptions({ showMeanings: newCheckBoxState });
        }
        if (checkBoxId === 'shuffleCards') {
            const newCheckBoxState = !checkBox2State;
            setCheckBox2State(newCheckBoxState);
            updateSuccess = await updateUserOptions({ shuffleCards: newCheckBoxState });
        }

        if (!updateSuccess) {
            showModal(<ModalSessionExpired />);
        }
    };

    return (
        <>
            <Helmet>
                <title>Configuración - Hanzi-cards.com</title>
                <meta name="description" content="Crea un usuario, modifica tus datos o configura distintas opciones de estudio." />
            </Helmet>

            <h1>Configuración</h1>
            <div className="buttons__wrapper">
                <div className="config__options">
                    <div className="config__option">
                        <CheckBox id="showHideMeanings" className="config__option-checkbox" checkBoxState={checkBox1State} toggleSwitchHandler={toggleSwitchHandler} />
                        <div className="config__option-explanation">
                            <h3>Mostrar significados</h3>
                            <p>Si está activado, se mostrarán por defecto los significados en las tarjetas de estudio. Si está desactivado, los significados estarán ocultos por defecto.</p>
                        </div>
                    </div>
                    <div className="config__option">
                        <CheckBox id="shuffleCards" className="config__option-checkbox" checkBoxState={checkBox2State} toggleSwitchHandler={toggleSwitchHandler} />
                        <div className="config__option-explanation">
                            <h3>Mezclar tarjetas</h3>
                            <p>Si está activado, las tarjetas de estudio se mezclarán y aparecerán en orden aleatorio. Si está desactivado, las tarjetas de estudio aparecerán ordenadas consecutivamente.</p>
                        </div>
                    </div>
                </div>

                {isGuest
                ? <Link to="/panel/config/create" className="config__button config__button-create-user">Crear usuario</Link>
                : (
                    <>
                        <Link
                          to={{ pathname: '/panel/config/edit', state: { edit: 'user' } }}
                          className="config__button config__button-create-user"
                        >Modificar usuario
                        </Link>
                        <Link
                          to={{ pathname: '/panel/config/edit', state: { edit: 'pass' } }}
                          className="config__button config__button-change-pass"
                        >Cambiar contraseña
                        </Link>
                    </>
                  )}
                <button className="config__button config__button-reset" onClick={openModalResetProgress}>Olvidar caracteres</button>
            </div>
        </>
    );
};

export default Config;
