import React, { useContext } from 'react';
import { HanziDataContext } from '../context/HanziDataContext';
import { UserDataContext } from '../context/UserDataContext';
import HanziCard from './HanziCard';
import './LearnLevel.css';

const LearnLevel = ({ openModalHandler, levelNumber }, ref) => {
    const { hanziListFromDatabase } = useContext(HanziDataContext);
    const { userHanziList, userLevel } = useContext(UserDataContext);

    const isCurrentLevel = levelNumber === userLevel;
    const levelCompleted = levelNumber < userLevel;
    const isNotLevelOne = levelNumber > 1;

    const LevelRevisionCard = () => (
        <div className="hanzi-card revision-card">
            <h3 className="revision-card__title">Tarjeta de revisión</h3>
        </div>
    );

    const LevelSectionTitle = () => (
        <h2 className={`learn__level-title ${levelCompleted ? 'learn__level-title--completed' : ''}`}>Nivel {levelNumber}</h2>
    );

    const LevelHanziCards = () => {
        const starterHanzi = Number((levelNumber * 5) - 4);
        const levelCards = hanziListFromDatabase.filter((item) => (
            item.id >= starterHanzi && item.id < (starterHanzi + 5)
        ));
        return (
            <div className="learn__level-cards">
                {levelCards.map((item) => <HanziCard key={item.id} hanzi={item} currentPage="learn" />)}
                <LevelRevisionCard />
            </div>
        );
    };

    const LevelButton = () => {
        const levelRevisionNotDoneYet = userHanziList.length === userLevel * 5;
        let buttonText = 'Nivel Bloqueado';
        let tooltipText = 'Completa los niveles anteriores para acceder a este nivel';

        if (levelNumber === userLevel) {
            if (levelRevisionNotDoneYet) {
                buttonText = 'Hacer prueba de nivel';
            } else {
                buttonText = 'Aprende este nivel';
            }
            tooltipText = '';
        }
        if (levelNumber < userLevel) {
            buttonText = 'Nivel Completado';
            tooltipText = '¡Ya has completado este nivel, completa los siguientes niveles!';
        }

        return (
            <button
              onClick={isCurrentLevel ? () => openModalHandler() : null}
              data-tooltip={isCurrentLevel ? '' : tooltipText}
              className={`learn__level-button ${isCurrentLevel ? 'learn__level-button--current' : 'learn__level-button--locked'}`}
            >
                {buttonText}
            </button>
        );
    };

    return (
        <div ref={isCurrentLevel && isNotLevelOne ? ref : null} className="learn__level-block">
            <LevelSectionTitle />
            <LevelHanziCards />
            <LevelButton />
        </div>
    );

};

export default React.forwardRef(LearnLevel);
