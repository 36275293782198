import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ModalContext } from '../../context/ModalContext';

const ModalEditUser = () => {
    const { setModalState } = useContext(ModalContext);

    const closeModal = () => {
        setModalState(false);
    };

    return (
        <div className="modal__content modal__edit-user">
            <h2>¡Datos modificados!</h2>
            <div>
                <p>Tus datos se han modificado correctamente.</p>
                <p>Usa los nuevos datos para iniciar sesión a partir de ahora.</p>
            </div>
            <div className="buttons__wrapper">
                <Link to="/panel" onClick={closeModal} className="modal__edit-user-button">Perfecto</Link>
            </div>
        </div>
    );
};

export default ModalEditUser;
