import { useContext } from 'react';
import { UserDataContext } from '../context/UserDataContext';
import { UserLoginContext } from '../context/UserLoginContext';

const useResetStates = () => {
    const { setUserName, setUserLevel, setUserHanziList, setUserOptions } = useContext(UserDataContext);
    const { setIsLogged } = useContext(UserLoginContext);

    const resetStates = () => {
        window.localStorage.clear();
        setUserName('');
        setUserHanziList([]);
        setIsLogged(false);
        setUserOptions({});
        setUserLevel(1);
    };

    return [resetStates];
};

export default useResetStates;
