import ReactGA from 'react-ga';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserDataContext } from '../../context/UserDataContext';
import { ModalContext } from '../../context/ModalContext';
import createNewGuest from '../../helpers/createNewGuest';
import updateLocalStorage from '../../helpers/updateLocalStorage';

const ModalGuestSession = () => {
    const { setUserId, setUserLevel, setUserOptions, setToken } = useContext(UserDataContext);
    const { setModalState } = useContext(ModalContext);

    const closeModal = () => {
        setModalState(false);
    };

    const startGuestSession = () => {
        createNewGuest()
        .then((res) => {
            updateLocalStorage('userData', { isLogged: true,
                                             isGuest: true,
                                             id: Number(res.id),
                                             userLevel: 1,
                                             userHanziList: [],
                                             email: null,
                                             token: res.token,
                                             userOptions: { showMeanings: false,
                                                           shuffleCards: false }
                                           });
            setUserId(Number(res.id));
            setToken(res.token);
            setUserLevel(1);
            setUserOptions({ showMeanings: false, shuffleCards: false });
            ReactGA.event({
                category: 'General',
                action: 'Creada cuenta de invitado'
            });
        });
        closeModal();
    };

    return (
        <div className="modal__content modal__guest-session">
            <h2>Sesión de invitado</h2>
            <p>Estás usando la plataforma con una sesión de invitado, puedes usar todas las funciones de la web, pero <span>si cierras sesión se perderá todo tu progreso.</span></p>
            <p>Puedes guardar tu progreso en cualquier momento desde el menú de opciones indicando un nombre de usuario y una contraseña con los que iniciar sesión en futuras ocasiones.</p>
            <div className="buttons__wrapper">
                <Link to="/panel/config/create" onClick={startGuestSession} className="modal__guest-button modal__guest-button--create">Crear usuario ahora</Link>
                <button onClick={startGuestSession} className="modal__guest-button modal__guest-button--skip">Seguir como invitado</button>
            </div>
            <div className="modal__guest-already-register">
                <p>¿Ya tienes un nombre de usuario?</p>
                <Link to="/login" onClick={closeModal} className="modal__guest-link">Inicia Sesión</Link>
            </div>
        </div>
    );
};

export default ModalGuestSession;
