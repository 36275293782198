import Learnlevel from './LearnLevel';

const LearnLevels = ({ totalLevels, openModalHandler, activeLevelBlock }) => {
    const LearnTotalLevels = [];
    for (let i = 1; i <= totalLevels; i++) {
        LearnTotalLevels.push(
            <Learnlevel key={i} levelNumber={i} openModalHandler={openModalHandler} ref={activeLevelBlock} />
        );
    }

    return LearnTotalLevels;
};

export default LearnLevels;
