import { useContext } from 'react';
import { UserDataContext } from '../context/UserDataContext';
import './HanziCard.css';

const HanziCard = ({ hanzi, currentPage, onClickHandler }) => {
    const { userHanziList } = useContext(UserDataContext);
    const hanziIsAlreadyLearned = userHanziList.find((item) => hanzi.id === item.id);
    const backgroundColors = ['#ff7347', '#fa9b50', '#f9be60', '#fee481', '#e3f28b', '#c8ff95', '#bbfb87', '#aef678', '#a2f26a', '#96ef5d', '#89ea4f'];
    let color;
    let cursorType = 'initial';

    const setCardClass = () => {
        if (hanziIsAlreadyLearned && currentPage === 'learn') return 'hanzi-card--learned';
        if (hanziIsAlreadyLearned && currentPage === 'cards') {
            const { learnLevel } = hanziIsAlreadyLearned;
            color = backgroundColors[learnLevel + 5];
            cursorType = 'pointer';
            return 'hanzi-card--list';
        }

        if (!hanziIsAlreadyLearned && currentPage === 'cards') return 'hanzi-card--list hanzi-card--neutral';

        return 'hanzi-card--neutral';

    };

    const HanziCardContent = () => {
        if (!hanziIsAlreadyLearned) return <div className="hanzi-card__primary">?</div>;
        return (
            <>
                <div className="hanzi-card__primary">{hanzi.hanzi}</div>
                <div className="hanzi-card__secondary"> {hanzi.pinyin}</div>
            </>
        );
    };

    const cardClass = setCardClass();

    return (
        <div
          className={`hanzi-card ${cardClass}`}
          style={{ backgroundColor: color, cursor: cursorType }}
          onClick={hanziIsAlreadyLearned && currentPage === 'cards' ? () => onClickHandler(hanzi) : null}
        >
            <div className="hanzi-card__id">Nº {hanzi.id}</div>
            <HanziCardContent />
        </div>
    );
};

export default HanziCard;
