import React, { useState } from 'react';
import { updateProgressInDatabase, updateOptionsInDatabase } from '../helpers/updateDatabase';
import updateLocalStorage from '../helpers/updateLocalStorage';

export const UserDataContext = React.createContext();

export const UserDataContextProvider = ({ children }) => {
    const [userHanziList, setUserHanziList] = useState(getInitialState('userHanziList'));
    const [userLevel, setUserLevel] = useState(getInitialState('userLevel'));
    const [userName, setUserName] = useState(getInitialState('username'));
    const [userEmail, setUserEmail] = useState(getInitialState('email'));
    const [token, setToken] = useState(getInitialState('token'));
    const [isGuest, setIsGuest] = useState(getInitialState('isGuest'));
    const [userId, setUserId] = useState(getInitialState('id'));
    const [userOptions, setUserOptions] = useState(getInitialState('userOptions'));

    function getInitialState(item) {
        const data = JSON.parse(localStorage.getItem('userData'));
        const itemsWithDefaultNull = ['token', 'username', 'userLevel', 'email', 'id'];

        if (itemsWithDefaultNull.includes(item)) return data?.[item] || null;
        if (item === 'isGuest') return data ? data.isGuest : true;
        if (item === 'userHanziList') return data?.userHanziList ? data.userHanziList : [];
        if (item === 'userOptions') return data?.userOptions ? data.userOptions : { showMeanings: false, shuffleCards: false };

        return false;
    }

    const setUserLevelUp = () => {
        const newLevel = userLevel + 1;
        setUserLevel(newLevel);
        updateProgressInDatabase(userId, newLevel, userHanziList, token);
        updateLocalStorage('userData', { userLevel: newLevel });
    };

    const resetUserLevel = async () => {
        setUserLevel(1);
        updateLocalStorage('userData', { userLevel: 1 });
        const updateResponse = await updateProgressInDatabase(userId, 1, [], token);

        return checkResponseMessage(updateResponse.message);
    };

    const updateUserHanziList = async (newHanziList) => {
        setUserHanziList(newHanziList);
        updateLocalStorage('userData', { userHanziList: newHanziList });
        const updateResponse = await updateProgressInDatabase(userId, userLevel, newHanziList, token);

        return checkResponseMessage(updateResponse.message);
    };

    const updateUserOptions = async (updatedOption) => {
        const newOptions = { ...userOptions, ...updatedOption };
        setUserOptions(newOptions);
        updateLocalStorage('userData', { userOptions: newOptions });
        const updateResponse = await updateOptionsInDatabase(userId, newOptions, token);

        return checkResponseMessage(updateResponse.message);
    };

    const checkResponseMessage = (message) => {
        if (message === 'Token inválido o expirado') {
            return false;
        }
        return true;
    };

    const contextValues = { userName, setUserName, token, setToken, userHanziList, setUserHanziList, updateUserHanziList, isGuest, setIsGuest, userId, setUserId, userLevel, setUserLevel, setUserLevelUp, resetUserLevel, userEmail, setUserEmail, userOptions, setUserOptions, updateUserOptions };

    return (
        <UserDataContext.Provider value={contextValues}>
           { children }
        </UserDataContext.Provider>
    );
};
