import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserLoginContext } from '../context/UserLoginContext';
import './NavLogo.css';

const NavLogo = () => {
    const { isLogged } = useContext(UserLoginContext);

    const logoLink = () => {
        return isLogged ? '/panel' : '/';
    };

    return (
        <Link className="nav__logo" to={logoLink}>
            <img src="/img/logo64.png" alt="" />
            <p><span>H</span>anzi<span>C</span>ards</p>
        </Link>
    );
};

export default NavLogo;
