import { useContext } from 'react';
import ArcProgressBar from './ArcProgressBar';
import { HanziDataContext } from '../context/HanziDataContext';
import { UserDataContext } from '../context/UserDataContext';
import './Graphs.css';

const Graphs = () => {
    const { hanziListFromDatabase } = useContext(HanziDataContext);
    const { userHanziList, userLevel } = useContext(UserDataContext);

    const hanziAlreadyLearned = userHanziList.length;
    const hanziTotal = hanziListFromDatabase.length;
    const hanziPercentage = (100 / hanziTotal) * hanziAlreadyLearned;

    const currentLevel = userLevel;
    const totalLevels = hanziListFromDatabase.length / 5;
    const levelPercentage = (100 / totalLevels) * currentLevel;

    return (
        <div className="graphs__container">
            <div className="graph__single">
                <div className="graph__content">
                    <h3 className="graph__title">Caracteres</h3>
                    <span className="graph__number">{userHanziList.length}/{hanziListFromDatabase.length}</span>
                </div>
                <ArcProgressBar graphId="graph__characters" percentage={hanziPercentage} backgroundClass="progressbar__background--hanzi" strokeClass="progressbar__stroke--hanzi" />
            </div>
            <div className="graph__single">
                <div className="graph__content">
                    <h3 className="graph__title">Nivel</h3>
                    <span className="graph__number">{userLevel}/{totalLevels}</span>
                </div>
                <ArcProgressBar graphId="graph__level" percentage={levelPercentage} backgroundClass="progressbar__background--level" strokeClass="progressbar__stroke--level" />
            </div>
        </div>
    );
};

export default Graphs;
