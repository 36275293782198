import { useRef, useState, useContext } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { UserDataContext } from '../context/UserDataContext';
import StudyCardOptions from './StudyCardOptions';
import './StudyCard.css';

const StudyCard = ({ cardData, onClickHandler, cardIndex, totalCards }) => {
    const { userOptions } = useContext(UserDataContext);
    const [meaningIsHide, setMeaningIsHide] = useState(userOptions.showMeanings);
    const cardOptions = useRef(null);
    const cardMeaning = useRef(null);

    const cardPosition = () => {
        if (cardIndex === totalCards - 1) return 'study-card--first';
        if (cardIndex === totalCards - 2) return 'study-card--second';
        return '';
    };

    const showHideMeaning = () => {
        setMeaningIsHide(!meaningIsHide);
        cardMeaning.current.classList.toggle('study-card__secondary--show');
    };

    const showMeaning = userOptions.showMeanings ? 'study-card__secondary--show' : '';

    return (
        <div className={`study-card study-card--${cardIndex} ${cardPosition()}`}>
            <div className="study-card__flip-wrapper">
                <div className="study-card__front">
                    {meaningIsHide
                    ? <AiFillEyeInvisible className="study-card__showhide-icon" onClick={showHideMeaning} />
                    : <AiFillEye className="study-card__showhide-icon" onClick={showHideMeaning} />}

                    <div className="study-card__primary">{cardData.hanzi}</div>
                    <div ref={cardMeaning} className={`study-card__secondary ${showMeaning}`}>{cardData.meaning}</div>
                    <div ref={cardOptions} className="study-card__options">
                        <StudyCardOptions cardData={cardData} cardIndex={cardIndex} onClickHandler={onClickHandler} ref={cardOptions} />
                    </div>
                </div>
                <div className="study-card__back" />
            </div>
        </div>
    );
};

export default StudyCard;
