import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ModalContext } from '../../context/ModalContext';

const ModalResetPassword = () => {
    const { setModalState } = useContext(ModalContext);

    const closeModal = () => {
        setModalState(false);
    };

    return (
        <div className="modal__content modal__reset-password">
            <h2>¡Contraseña restablecida!</h2>
            <p>Tu contraseña se ha restablecido correctamente, a partir de ahora usa tu nueva contraseña para iniciar sesión.</p>
            <div className="buttons__wrapper">
                <Link to="/login" onClick={closeModal} className="modal__reset-password-button">Perfecto</Link>
            </div>
        </div>
    );
};

export default ModalResetPassword;
