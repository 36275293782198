let voices;
const noVoices = 'Tu dispositivo no dispone de voces en chino para la reproducción de los caracteres, por favor inténtalo desde otro dispositivo.';
window.speechSynthesis.getVoices();

const selectChineseVoice = () => {
    voices = window.speechSynthesis.getVoices();

    for (let i = 0; i < voices.length; i++) {
      if (voices[i].lang == 'zh-CN' || voices[i].lang == 'zh_CN') {
        return i;
      }
    }

    return false;
};

const playAudio = async (hanzi) => {
    const msg = new SpeechSynthesisUtterance();
    const voz = selectChineseVoice();
    if (voz === false) {
      alert(noVoices);
    } else {
      msg.voice = voices[voz];
      msg.volume = 1;
      msg.rate = 0.5;
      msg.pitch = 1;
      msg.lang = voices[voz].lang;
      msg.text = hanzi;
      speechSynthesis.speak(msg);
    }
};

export default playAudio;
