import axios from 'axios';
import { createGuestUrl } from './apiUrls';

const createNewGuest = async () => {
    const data = await axios.post(createGuestUrl, { key: 'hanzicards' })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });

    return data;
};

export default createNewGuest;
